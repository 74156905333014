import React from "react";

const role = localStorage.getItem("user-role");

const Admin_Home = React.lazy(() => import("../views/dashboard/Home"));
const Teacher_Home = React.lazy(() => import("../Teacher_Views/Home"));

// student dashboard
const Student_Home = React.lazy(() => import("../Student_dashboard/Home"));
const Student_Profile = React.lazy(() =>
  import("../Student_dashboard/Profile")
);
const Student_Profile_Edit = React.lazy(() =>
  import("../Student_dashboard/ProfileEdit")
);
const Student_Fees = React.lazy(() =>
  import("../Student_dashboard/StudentFees")
);
const Student_TimeTable = React.lazy(() =>
  import("../Student_dashboard/StudentTimeTable")
);
const Student_Attendance_List = React.lazy(() =>
  import("../Student_dashboard/AttendanceList")
);
const Student_Exam_Schedule_List = React.lazy(() =>
  import("../Student_dashboard/ExamSchedule")
);
const Student_Exam_Result_List = React.lazy(() =>
  import("../Student_dashboard/ExamResult")
);
const Student_Leave_List = React.lazy(() =>
  import("../Student_dashboard/ApplyLeave")
);

// Profile view
const Profile_View = React.lazy(() =>
  import("../views/profileView/ProfileView")
);

// student profile view
const Student_Profile_View = React.lazy(() =>
  import("../views/student/ViewStudentProfile")
);

// Profile edit
const Profile_Edit = React.lazy(() =>
  import("../views/profileEdit/ProfileEdit")
);
const Profile_Edit_Staff = React.lazy(() =>
  import("../views/profileEdit/ProfileStaffEdit")
);

// Student
const Student_Detail = React.lazy(() =>
  import("../views/student/StudentDetails")
);
const Admin_Student_Add = React.lazy(() =>
  import("../views/student/StudentRegister")
);
const Student_Import = React.lazy(() =>
  import("../views/student/StudentImport")
);
const Student_Delete = React.lazy(() =>
  import("../views/student/StudentDelete")
);
const Student_Edit = React.lazy(() => import("../views/student/EditStudent"));

// Staff
const Manage_Hod = React.lazy(() => import("../views/staff/ManageHod"));
const Staff_Detail = React.lazy(() => import("../views/staff/StaffDetails"));
const Staff_Register = React.lazy(() => import("../views/staff/StaffRegister"));
const Staff_Import = React.lazy(() => import("../views/staff/StaffImport"));
const Leave_Request = React.lazy(() => import("../views/staff/LeaveRequest"));
const Leave_Type = React.lazy(() => import("../views/staff/LeaveType"));
const Staff_Leave = React.lazy(() => import("../Teacher_Views/ApplyLeave"));

// HOD
const Hod_Teacher_Detail = React.lazy(() =>
  import("../Hod_dashboard/TeacherDetails")
);
const Hod_Teacher_Add = React.lazy(() => import("../Hod_dashboard/AddTeacher"));
const Teacher_Import = React.lazy(() =>
  import("../Hod_dashboard/ImportTeacher")
);
const Hod_Student_Add = React.lazy(() => import("../Hod_dashboard/AddStudent"));
const Hod_Student_Import = React.lazy(() =>
  import("../Hod_dashboard/ImportStudent")
);
const Hod_Student_Detail = React.lazy(() =>
  import("../Hod_dashboard/DetailsStudent")
);
const Student_Disable_Reason = React.lazy(() => import("../views/student/DisableReason"));
const Hod_Home = React.lazy(() => import("../Hod_dashboard/Home"));
const Hod_Timetable = React.lazy(() => import("../Hod_dashboard/TimeTable"));
const Hod_TimetableAdd = React.lazy(() =>
  import("../Hod_dashboard/AddTimeTable")
);
const Group_Student_By_Subject = React.lazy(() =>
  import("../Hod_dashboard/GroupStudentBySubject")
);
const Mark_Attendance_Hod = React.lazy(() =>
  import("../Hod_dashboard/StudentAttendanceHod")
);
const Daily_Attendance_Report_Hod = React.lazy(() =>
  import("../Hod_dashboard/DailyAttendanceReport")
);
const Monthly_Attendance_Report_Hod = React.lazy(() =>
  import("../Hod_dashboard/MonthlyAttendenceReport")
);
const Subject_Attendance_Report_Hod = React.lazy(() =>
  import("../Hod_dashboard/ReportBySubject")
);
const Attendance_Report_Branch = React.lazy(() =>
  import("../Hod_dashboard/ReportByBranch")
);

// Attendence
const Student_attendence = React.lazy(() =>
  import("../views/attendence/StudentAttendence")
);
const Student_Attendence_data = React.lazy(() =>
  import("../views/attendence/AttendenceByDate")
);
const Student_Attendence_Performance = React.lazy(() =>
  import("../views/report/StudentAttendencePercent")
);
const Student_Attendence_By_Teacher = React.lazy(() =>
  import("../Teacher_Views/AttendanceStudent")
);
const Attendance_Report_daily = React.lazy(() =>
  import("../Teacher_Views/AttendanceReportDaily")
);
const Attendance_Report_Monthly = React.lazy(() =>
  import("../Teacher_Views/AttendanceMonthlyReportBySubject")
);
const Attendance_Report_By_Subject = React.lazy(() =>
  import("../Teacher_Views/SubjectAttendanceReport")
);
const Delete_Student_Attendance = React.lazy(() =>
  import("../views/attendence/DeleteStudentAttendance")
);

// Attendance Reports
const Attendance_Report = React.lazy(() =>
  import("../views/report/AttendanceReports")
);

// Manage Exam
const Exam_Grade = React.lazy(() => import("../views/manageExam/ExamGrade"));
const Exam_Term = React.lazy(() => import("../views/manageExam/ExamTerm"));
const Exam_Grade_Add = React.lazy(() =>
  import("../views/manageExam/AddExamGrade")
);
const Exam_Term_Add = React.lazy(() =>
  import("../views/manageExam/AddExamTerm")
);

// Teacher Attendence
const Teacher_Attendence = React.lazy(() =>
  import("../views/attendence/TeacherAttendence")
);
// Employee Attendence
const Employee_Attendence = React.lazy(() =>
  import("../views/attendence/EmployeeAttendence")
);

// Absent sms
const Absent_Sms = React.lazy(() => import("../views/attendence/AbsentSms"));

// Transport
const Transport_route = React.lazy(() =>
  import("../views/transport/RouteLocation")
);
const Transport_vehicle = React.lazy(() =>
  import("../views/transport/VehicalList")
);
const Transport_vehroute = React.lazy(() =>
  import("../views/transport/AssignVehical")
);

// Academic
const Add_course = React.lazy(() => import("../views/academic/AddCourse"));
const Add_Branch = React.lazy(() => import("../views/academic/AddBranch"));
const Add_Section = React.lazy(() => import("../views/academic/AddSection"));
const Add_Sub_Section = React.lazy(() =>
  import("../views/academic/SubSection")
);
const Add_subject_type = React.lazy(() =>
  import("../views/academic/AddSubjectType")
);
const Add_subject = React.lazy(() => import("../views/academic/AddSubject"));
const Add_subject_Group = React.lazy(() =>
  import("../views/academic/SubjectGroup")
);
const Add_Student_Group = React.lazy(() =>
  import("../views/academic/AddStudentGroup")
);
const Add_Class = React.lazy(() => import("../views/academic/AddClass"));

const Class = React.lazy(() =>
  import("../views/academic/class manage/ClassManage")
);
const Class_Teacher_Add = React.lazy(() =>
  import("../views/academic/class manage/AddClassTeacher")
);
const Guardian_Add = React.lazy(() => import("../views/academic/guardianAdd"));
const Guardian_List = React.lazy(() =>
  import("../views/academic/guardianList")
);
const Time_Table_Report = React.lazy(() =>
  import("../views/academic/TimeTableReport")
);
const Time_Table_Add = React.lazy(() =>
  import("../views/academic/AddTimeTable")
);

// Administrator
const Academic_Year = React.lazy(() =>
  import("../views/administrator/AcademicYear")
);
const Manage_User = React.lazy(() =>
  import("../views/administrator/ManageUser")
);
const Department = React.lazy(() => import("../views/staff/Department"));
const Desgination = React.lazy(() => import("../views/staff/Desgination"));
const Role = React.lazy(() => import("../views/staff/Role"));

// Library
const Book_list = React.lazy(() => import("../views/library/BookList"));
const Member_List = React.lazy(() => import("../views/library/IssueReturn"));
const AddStudent = React.lazy(() => import("../views/library/AddStudent"));
const AddStaff = React.lazy(() => import("../views/library/AddStaffMember"));
const Member_Issue = React.lazy(() =>
  import("../views/library/MemberIssueReturn")
);

// Change Password
const ChangePassword = React.lazy(() =>
  import("../views/changePassword/PasswordChange")
);

// Accountant Routes
const Accountant_Home = React.lazy(() => import("../Accountant_Views/Home"));

// Fees Collection
const FeesType = React.lazy(() => import("../views/fees collection/FeesType"));

const routes = [
  // Attendance report routes
  {
    path: "/attendancereports/attendance",
    name: "Attendance_Report",
    element: Attendance_Report,
  },

  // admin all routes
  { path: "/admin/dashboard", name: "Admin_Dashboard", element: Admin_Home },
  { path: "/admin/course/add", name: "Add_Course", element: Add_course },
  { path: "/admin/branch/add", name: "Add_Branch", element: Add_Branch },
  { path: "/admin/section/add", name: "Add_Section", element: Add_Section },
  {
    path: "/admin/subsection/add",
    name: "Add_Sub_Section",
    element: Add_Sub_Section,
  },
  {
    path: "/admin/student/details",
    name: "Student_Details",
    element: Student_Detail,
  },
  {
    path: "/admin/student/register",
    name: "Hod_Student_Add",
    element: Admin_Student_Add,
  },
  // fees collection route
  { path: "/admin/feetype", name: "FeesType", element: FeesType },

  // All hod routes
  { path: "/hod/dashboard", name: "Hod_Dashboard", element: Hod_Home },
  {
    path: "/hod/timetable/report",
    name: "Hod_Timetable",
    element: Hod_Timetable,
  },
  {
    path: "/hod/timetable/add",
    name: "Hod_TimetableAdd",
    element: Hod_TimetableAdd,
  },
  {
    path: "/student/disable_reason",
    name: "Student_Disable_Reason",
    element: Student_Disable_Reason,
  },
  
  {
    path: "/hod/group/student/bysubject",
    name: "Group_Student_By_Subject",
    element: Group_Student_By_Subject,
  },
  {
    path: "/hod/stuattendence/index",
    name: "Mark_Attendance_Hod",
    element: Mark_Attendance_Hod,
  },
  {
    path: "/hod/stuattendence/performance/daily",
    name: "Daily_Attendance_Report_Hod",
    element: Daily_Attendance_Report_Hod,
  },
  {
    path: "/hod/stuattendencereport/bybranch",
    name: "Attendance_Report_Branch",
    element: Attendance_Report_Branch,
  },
  {
    path: "/hod/stuattendence/monthly",
    name: "Monthly_Attendance_Report_Hod",
    element: Monthly_Attendance_Report_Hod,
  },
  {
    path: "/hod/stuattendencereport/bysubject",
    name: "Subject_Attendance_Report_Hod",
    element: Subject_Attendance_Report_Hod,
  },

  // Exam manage
  { path: "/admin/exam/grade/index", name: "Exam_Grade", element: Exam_Grade },
  {
    path: "/admin/exam/grade/add",
    name: "Exam_Grade_Add",
    element: Exam_Grade_Add,
  },
  { path: "/admin/exam/index", name: "Exam_Term", element: Exam_Term },
  { path: "/admin/exam/add", name: "Exam_Term_Add", element: Exam_Term_Add },
  {
    path: `/admin/staff/profile/:id`,
    name: "Staff_Profile",
    element: Profile_View,
  },
  {
    path: `/student/profile/:id`,
    name: "Student_Profile_View",
    element: Student_Profile_View,
  },
  {
    path: `/admin/staff/edit/:id`,
    name: "Profile_Edit",
    element: Profile_Edit,
  },
  {
    path: `/admin/staffs/edit/:id`,
    name: "Profile_Edit_Staff",
    element: Profile_Edit_Staff,
  },
  {
    path: `/admin/admin/changepass`,
    name: "ChangePassword",
    element: ChangePassword,
  },

  {
    path: "/hod/student/details",
    name: "Hod_Student_Detail",
    element: Hod_Student_Detail,
  },
  {
    path: "/hod/student/register",
    name: "Hod_Student_Add",
    element: Hod_Student_Add,
  },
  {
    path: "/student/edit/:id",
    name: "Student_Edit",
    element: Student_Edit,
  },
  {
    path: "/hod/student/import",
    name: "Hod_Student_Import",
    element: Hod_Student_Import,
  },
  {
    path: "/admin/student/bulkDelete",
    name: "Student_Delete",
    element: Student_Delete,
  },
  {
    path: "/admin/staff",
    name: "Staff_Details",
    element: Staff_Detail,
  },
  {
    path: "/hod/teacher/details",
    name: "Hod_Teacher_Detail",
    element: Hod_Teacher_Detail,
  },
  {
    path: "/hod/teacher/add",
    name: "Hod_Teacher_Add",
    element: Hod_Teacher_Add,
  },
  {
    path: "/admin/staff/register",
    name: "Staff_Register",
    element: Staff_Register,
  },
  {
    path: "/hod/teacher/import",
    name: "Teacher_Import",
    element: Teacher_Import,
  },
  { path: "/admin/staff/import", name: "Staff_Import", element: Staff_Import },
  {
    path: "/admin/staff/approveleaverequest",
    name: "Leave_List",
    element: Leave_Request,
  },
  { path: "/admin/leavetypes", name: "Leave_Types", element: Leave_Type },
  { path: "/admin/department", name: "Department", element: Department },
  { path: "/admin/desgination", name: "Desgination", element: Desgination },
  { path: "/admin/hod/index", name: "Manage_Hod", element: Manage_Hod },
  { path: "/admin/role/index", name: "Role", element: Role },
  {
    path: "/admin/stuattendence/index",
    name: "Student_Attendence",
    element: Student_attendence,
  },
  {
    path: "/admin/stuattendence/delete",
    name: "Delete_Student_Attendance",
    element: Delete_Student_Attendance,
  },
  {
    path: "/admin/teacherattendence/index",
    name: "Teacher_Attendence",
    element: Teacher_Attendence,
  },
  {
    path: "/admin/employeeattendence/index",
    name: "Teacher_Attendence",
    element: Employee_Attendence,
  },
  {
    path: "/admin/attendence/absentsms/index",
    name: "Absent_Sms",
    element: Absent_Sms,
  },
  {
    path: "/teacher/stuattendence/index",
    name: "Student_Attendence",
    element: Student_Attendence_By_Teacher,
  },
  {
    path: "/teacher/stuattendence/performance/daily",
    name: "Attendance_Report_daily",
    element: Attendance_Report_daily,
  },
  {
    path: "/teacher/stuattendencereport/monthly",
    name: "Attendance_Report_Monthly",
    element: Attendance_Report_Monthly,
  },
  {
    path: "/teacher/stuattendencereport/bysubject",
    name: "Attendance_Report_By_Subject",
    element: Attendance_Report_By_Subject,
  },
  { path: "/admin/route", name: "Transport_Route", element: Transport_route },
  {
    path: "/admin/vehicle",
    name: "Transport_Vehicle",
    element: Transport_vehicle,
  },
  {
    path: "/admin/vehicle-route",
    name: "Transport_vehicle_route",
    element: Transport_vehroute,
  },
  {
    path: "/admin/subjecttype/add",
    name: "Add_subject_type",
    element: Add_subject_type,
  },
  { path: "/admin/subject/add", name: "Add_Subject", element: Add_subject },
  {
    path: "/admin/subjectgroup/add",
    name: "Add_Subject_Group",
    element: Add_subject_Group,
  },
  { path: "/admin/class/add", name: "Add_Class", element: Add_Class },
  { path: "/admin/academic/class/index", name: "Class", element: Class },
  {
    path: "/admin/academic/classteacher/add",
    name: "Class_Teacher_Add",
    element: Class_Teacher_Add,
  },
  { path: "/guardian/index", name: "Guardian_List", element: Guardian_List },
  { path: "/guardian/add", name: "Guardian_Add", element: Guardian_Add },
  {
    path: "/admin/timetable/classreport",
    name: "Time_Table_Report",
    element: Time_Table_Report,
  },
  {
    path: "/admin/timetable/create",
    name: "Time_Table_Add",
    element: Time_Table_Add,
  },

  { path: "/admin/book/all", name: "Book_List", element: Book_list },
  { path: "/admin/members", name: "Member_List", element: Member_List },
  {
    path: "/admin/member/issue/:id",
    name: "Member_Issue",
    element: Member_Issue,
  },
  { path: "/admin/member/student", name: "Add_Student", element: AddStudent },
  { path: "/admin/member/staff", name: "Add_Staff", element: AddStaff },

  // student dashboard routes
  { path: "/student/dashboard", name: "Student_Home", element: Student_Home },
  {
    path: "/student/profile",
    name: "Student_Profile",
    element: Student_Profile,
  },
  {
    path: `/student/profile/edit/:id`,
    name: "Student_Profile_Edit",
    element: Student_Profile_Edit,
  },
  { path: "/student/getfees", name: "Student_Fees", element: Student_Fees },
  {
    path: "/student/timetable",
    name: "Student_TimeTable",
    element: Student_TimeTable,
  },
  {
    path: "/student/attendance/list",
    name: "Student_Attendance_List",
    element: Student_Attendance_List,
  },
  {
    path: "/student/examschedule",
    name: "Student_Exam_Schedule_List",
    element: Student_Exam_Schedule_List,
  },
  {
    path: "/student/examresult",
    name: "Student_Exam_Result_List",
    element: Student_Exam_Result_List,
  },
  {
    path: "/student/leavelist",
    name: "Student_Leave_List",
    element: Student_Leave_List,
  },

  // Teacher Routes
  {
    path: "/teacher/dashboard",
    name: "Teacher_Dashboard",
    element: Teacher_Home,
  },
  {
    path: "/teacher/student/details",
    name: "Student_Details",
    element: Student_Detail,
  },
  // {
  //   path: "/teacher/student/register",
  //   name: "Student_Register",
  //   element: Student_Register,
  // },
  // {
  //   path: "/teacher/student/import",
  //   name: "Student_Import",
  //   element: Student_Import,
  // },
  // {
  //   path: "/teacher/student/bulkDelete",
  //   name: "Student_Delete",
  //   element: Student_Delete,
  // },
  {
    path: "/teacher/staff/details",
    name: "Staff_Details",
    element: Staff_Detail,
  },
  {
    path: "/teacher/addleave",
    name: "Staff_Leave",
    element: Staff_Leave,
  },
  {
    path: "/teacher/stuattendence/index",
    name: "Student_Attendence",
    element: Student_attendence,
  },
  {
    path: "/teacher/stuattendence/reportbydate",
    name: "Student_Attendence_data",
    element: Student_Attendence_data,
  },
  {
    path: "/teacher/stuattendence/performance",
    name: "Student_Attendence_Performance",
    element: Student_Attendence_Performance,
  },
  { path: "/teacher/course/add", name: "Add_Course", element: Add_course },
  { path: "/teacher/subject/add", name: "Add_Subject", element: Add_subject },
  {
    path: "/teacher/group/add",
    name: "Add_Student_Group",
    element: Add_Student_Group,
  },

  // Accountant
  {
    path: "/accountant/dashboard",
    name: "Accountant_Dashboard",
    element: Accountant_Home,
  },

  // Administrator
  {
    path: "/administrator/year/index",
    name: "Academic_Year",
    element: Academic_Year,
  },
  {
    path: "/administrator/user/index",
    name: "Manage_User",
    element: Manage_User,
  },
];

export default routes;
