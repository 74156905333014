import React, { useEffect, useState, useContext } from "react";
import "../Css/admin.css";
import { useSelector, useDispatch } from "react-redux";
import { CSidebar, CSidebarNav, CSidebarToggler } from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import imgLogo from "../assets/images/ice-logo.jpeg";
import { REGISTER_API } from "../AllApi/addStaffApi";
import UserContext from "../UserContext";
import { Link } from "react-router-dom";

// sidebar nav config
import adminNavigation from "../Menus/adminMenu";
import hodNavigation from "../Menus/hodMenu";
import studentNavigation from "../Menus/studentMenu";
import teacherNavigation from "../Menus/teacherMenu";
import accountantNavigation from "../Menus/accountantMenu";

const AppSidebar = () => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
      style={{ backgroundColor: "#333", width: "13.2rem" }}
    >
      <Link
        to={`/${user?.userRole?.toLowerCase()}/dashboard`}
        style={{ textDecoration: "none" }}
        className="mt-3"
      >
        <span className="college-logo-side mx-auto d-block">Dashboard</span>
      </Link>
      <CSidebarNav style={{ marginTop: "16px" }} className="pb-2">
        <SimpleBar>
          <AppSidebarNav
            items={
              user?.userRole?.toLowerCase() === "admin"
                ? adminNavigation
                : user?.userRole?.toLowerCase() === "teacher"
                ? teacherNavigation
                : user?.userRole?.toLowerCase() === "accountant"
                ? accountantNavigation
                : user?.userRole?.toLowerCase() === "student"
                ? studentNavigation
                : user?.userRole?.toLowerCase() === "hod"
                ? hodNavigation
                : ""
            }
          />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
