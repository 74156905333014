import React from "react";
import {FaChartLine, FaUserPlus, FaAngleDoubleRight, FaMapSigns, FaCalendarCheck, FaSitemap, FaRegMap, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlineMap, MdOutlineMenuBook } from "react-icons/md";
import { CNavGroup, CNavItem } from "@coreui/react";
import { GoMortarBoard } from "react-icons/go";

const Sidebar_Menu = [
  {
    component: CNavGroup,
    name: "Student Information",
    icon: <FaUserPlus className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Student Details",
        to: "/admin/student/details",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Student Admission",
        to: "/admin/student/register",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Disabled Students",
        to:"/student/disablestudentslist",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Student Categories",
        to:"/category",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Disable Reason",
        to:"/student/disable_reason",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      // {
      //   component: CNavItem,
      //   name: "Student Admission",
      //   to: "/hod/student/register",
      //   icon:<FaAngleDoubleRight className="" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Bulk Admission",
      //   to: "/hod/student/import",
      //   icon:<FaAngleDoubleRight className="" />
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "Fees Collection",
    icon: <FaMoneyBillAlt  className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Collect Fees",
        to: "/studentfee",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Fees Payment",
        to: "/studentfee/searchpayment",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Due Fees",
        to: "/studentfee/feesearch",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Master",
        to: "/admin/feemaster",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Group",
        to: "/admin/feegroup",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Type",
        to: "/admin/feetype",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Discount",
        to: "/admin/feediscount",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Carry Forward",
        to: "/admin/feesforward",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Fees Reminder",
        to: "/admin/feereminder/setting",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Behaviour Records",
    icon: <FaMapSigns className=" nav-icon menu-icon" />,
    items: [
      // {
      //   component: CNavItem,
      //   name: "Assign Incident",
      //   to: "/behaviour/studentincidents",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Incidents",
      //   to:"/behaviour/incidents",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      {
        component: CNavItem,
        name: "Reports",
        to:"/behaviour/report",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Income",
    icon: <FaUserPlus className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Income",
        to: "/admin/student/details",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Income",
        to:"/student/disablestudentslist",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Income Head",
        to:"/category",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Expense",
    icon: <FaUserPlus className="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Expense",
        to: "/admin/student/details",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Search Expense",
        to:"/student/disablestudentslist",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Expense Head",
        to:"/category",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Examination",
    icon: <MdOutlineMap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Exam Grade",
        to: "/admin/exam/grade/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Exam Term",
        to: "/admin/exam/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Online Examination",
    icon: <MdOutlineMap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Online Exam",
        to: "/admin/exam/grade/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Question Bank",
        to: "/admin/exam/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Attendance",
    icon: <FaCalendarCheck className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Student Attendance",
        to: "/hod/stuattendence/index",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      // {
      //   component: CNavItem,
      //   name: "Daily Report",
      //   to: "/hod/stuattendence/performance/daily",
      //   icon: <FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Monthly Report",
      //   to: "/hod/stuattendence/monthly",
      //   icon: <FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Report By Section",
      //   to: "/hod/stuattendencereport/bysubject",
      //   icon: <FaAngleDoubleRight className="sub-nav-icon" />
      // },
      // {
      //   component: CNavItem,
      //   name: "Report By Branch",
      //   to: "/hod/stuattendencereport/bybranch",
      //   icon: <FaAngleDoubleRight className="sub-nav-icon" />
      // },
      {
        component: CNavItem,
        name: "Approve Leave",
        to: "/admin/approve_leave",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Academics",
    icon: <GoMortarBoard className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Section",
        to: "/admin/section/add",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Sub Section",
        to: "/admin/subsection/add",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Subject",
        to: "/admin/subject/add",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Subject Type",
        to: "/admin/subjecttype/add",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      // {
      //   component: CNavItem,
      //   name: "Class",
      //   to: "/admin/class/add",
      //   icon:<FaAngleDoubleRight className="sub-nav-icon" />
      // },
      {
        component: CNavItem,
        name: "Group Student By Subject",
        to: "/hod/group/student/bysubject",
        icon:<FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Class Timetable",
        to: "/hod/timetable/report",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Human Resource",
    icon: <FaSitemap className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Staff Directory",
        to: "/admin/staff",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
      {
        component: CNavItem,
        name: "Apply Leave",
        to: "/teacher/addleave",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Reports",
    icon: <FaChartLine className=" nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Attendance Report",
        to: "/attendancereports/attendance",
        icon: <FaAngleDoubleRight className="sub-nav-icon" />
      },
    ],
  },
];

export default Sidebar_Menu;
